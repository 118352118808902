import { createRouter, createWebHistory } from 'vue-router'; // Correct imports for Vue Router 4
import ComingSoon from '@/components/ComingSoon.vue'; // Homepage component
import DashboardView from '@/views/DashboardView.vue'; // Dashboard component

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ComingSoon, // Homepage at root
  },
  {
    path: '/twisted-syndicate',
    name: 'Dashboard',
    component: DashboardView, // Dashboard page at /twisted-minds
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
