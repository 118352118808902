<template>
  <UIkitLayout>
    <router-view /> <!-- This will render the current route's component -->
  </UIkitLayout>
</template>

<script>
import UIkitLayout from './components/UIkitLayout.vue';

export default {
  name: 'App',
  components: {
    UIkitLayout,
  },
};
</script>

<style>
/* Global styles */
</style>
