<template>
  <div>
    <!-- UIkit Navbar -->
    <nav class="uk-navbar-container">
      <div class="uk-container">
        <div uk-navbar>
          <div class="uk-navbar-left">
            <ul class="uk-navbar-nav">
              <li class="uk-active"><a href="#"><div class="twisted-logo"></div></a></li>
              <li>
                <a href="#">Parent</a>
                <div class="uk-navbar-dropdown">
                  <ul class="uk-nav uk-navbar-dropdown-nav">
                    <li class="uk-active"><a href="#">Active</a></li>
                    <li><a href="#">Item</a></li>
                    <li><a href="#">Item</a></li>
                  </ul>
                </div>
              </li>
              <li><a href="#">Item</a></li>
            </ul>
          </div>
          <div class="uk-navbar-right">
            <ul class="uk-navbar-nav">
              <li class="toggle-light-dark-mode uk-active" id="light-mode-toggle">
                <a href="#"><div class="light-mode"></div></a>
              </li>
              <li class="toggle-light-dark-mode" id="dark-mode-toggle" hidden>
                <a href="#"><div class="dark-mode"></div></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <!-- Main Content Wrapper -->
    <div class="uk-container uk-margin-top">
      <!-- UIkit Headline -->
      <h1 class="uk-heading-primary">Dashboard</h1>

      <div uk-grid>
        <div class="uk-width-1-2@m">
          <!-- WarHitsWidget Component -->
          <war-hits-widget 
            :factionData="factionData" 
            :warIds="formattedWarIds" 
            :selectedWarId="selectedWarId"
            @update:selectedWarId="handleWarIdChange"
          />
        </div>

        <div class="uk-width-1-2@m">
          <war-participation-widget
            :warParticipationData="warParticipationData" 
            :membershipData="membershipData"
            :isDataReady="isDataReady"
          />
        </div>

        <div class="uk-width-1-2@m">
          <war-full-participation-widget
            :warParticipationData="warParticipationData" 
            :membershipData="membershipData"
            :isDataReady="isDataReady"
          />
        </div>
      </div>

      <!-- Error Messaging -->
      <p v-if="errorMessage" class="uk-text-danger">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import WarFullParticipationWidget from '@/components/WarFullParticipationWidget.vue';
import WarHitsWidget from '@/components/WarHitsWidget.vue';
import WarParticipationWidget from '@/components/WarParticipationWidget.vue'; 
import io from 'socket.io-client';

export default {
  components: {
    WarHitsWidget,
    WarParticipationWidget,
    WarFullParticipationWidget
  },
  data() {
    return {
      socket: null,
      warIds: [],
      formattedWarIds: [],
      selectedWarId: '', 
      warReportsData: {}, 
      errorMessage: '',
      factionData: [], 
      warParticipationData: {}, 
      membershipData: [], 
      isDataReady: false 
    };
  },
  mounted() {
    this.initializeSocket();
    this.updateHtmlClass(); // Initialize body class based on initial visibility
    this.setupToggleListener(); // Set up the listener for toggle behavior
  },
  methods: {
    initializeSocket() {
      this.socket = io('http://82.112.250.106:3001');
      this.socket.on('connect', () => {
        this.fetchWarIds();
        this.fetchMembershipData(); 
      });

      this.socket.on('warIdsData', this.handleWarIdsData);
      this.socket.on('warReportByMemberData', this.handleWarReportData);
      this.socket.on('membershipData', this.handleMembershipData); 
      this.socket.on('api_error', this.handleApiError);
      this.socket.on('disconnect', () => {});
    },
    fetchWarIds() {
      if (this.socket && this.socket.connected) {
        this.socket.emit('fetchWarIds');
      } else {
        console.error('WebSocket connection is not open.');
      }
    },
    fetchAllWarReports() {
      if (this.socket && this.socket.connected) {
        this.socket.emit('fetchWarReportByMember'); 
      } else {
        console.error('WebSocket connection is not open.');
      }
    },
    fetchMembershipData() {
      if (this.socket && this.socket.connected) {
        this.socket.emit('fetchMembershipData'); 
      } else {
        console.error('WebSocket connection is not open.');
      }
    },
    handleWarIdChange(newWarId) {
      this.selectedWarId = newWarId;
    },
    handleWarIdsData(data) {
      this.warIds = data;
      this.formattedWarIds = this.warIds.map(item => {
        const date = new Date(item.end_timestamp * 1000);
        const formattedDate = date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        }).toUpperCase();
        return {
          id: item.war_id,
          displayDate: formattedDate, 
          warId: item.war_id,        
          date: date
        };
      }).sort((a, b) => b.date - a.date);

      if (this.formattedWarIds.length > 0) {
        this.selectedWarId = this.formattedWarIds[0].id;
        this.fetchAllWarReports(); 
      } else {
        console.error('No War IDs available.');
      }
    },
    handleWarReportData(data) {
      if (Array.isArray(data)) {
        data.forEach(report => {
          if (report.war_id) {
            this.warReportsData[report.war_id] = report;
          }
        });

        this.formatFactionData();
        this.formatWarParticipation();
      }
    },
    handleMembershipData(data) {
      this.membershipData = data;
    },
    formatFactionData() {
      this.factionData = Object.values(this.warReportsData).map(report => {
        const faction49411 = report.factions[49411]; 

        if (!faction49411) {
          return null; 
        }

        const totalAttacks = faction49411.attacks;
        const respect = Object.values(faction49411.members)
          .reduce((sum, member) => sum + member.score, 0); 

        return {
          war_id: report.war_id,
          attacks: totalAttacks,
          respect: respect,
          members: Object.entries(faction49411.members).map(([memberId, memberData]) => ({
            member_id: memberId,
            member_name: memberData.name,
            member_attacks: memberData.attacks,
            member_score: memberData.score
          }))
        };
      }).filter(Boolean); 
    },
    formatWarParticipation() {
      const memberParticipation = new Map();
      let totalWars = 0;

      Object.values(this.warReportsData).forEach(report => {
        totalWars++;

        const faction49411 = report.factions[49411];
        if (faction49411) {
          Object.entries(faction49411.members).forEach(([memberId, memberData]) => {
            if (!memberParticipation.has(memberId)) {
              memberParticipation.set(memberId, {
                member_name: memberData.name,
                member_wars_participated: 0,
                member_wars_present: 0 
              });
            }

            if (memberData.attacks > 0 || memberData.score > 0) {
              memberParticipation.get(memberId).member_wars_participated += 1;
            }

            memberParticipation.get(memberId).member_wars_present += 1;
          });
        }
      });

      const members = Array.from(memberParticipation.entries()).map(([memberId, memberData]) => {
        const participationPercentage = memberData.member_wars_present > 0
          ? (memberData.member_wars_participated / memberData.member_wars_present) * 100
          : 0;

        return {
          member_id: memberId,
          member_name: memberData.member_name,
          member_wars_participated: memberData.member_wars_participated,
          member_wars_present: memberData.member_wars_present,
          participation_percentage: participationPercentage
        };
      });

      this.warParticipationData = {
        total_wars: totalWars,
        members: members
      };

      this.isDataReady = true;
    },

    handleApiError(error) {
      console.warn('API error occurred while fetching war report:', error.message);
      
      if (!this.errorMessage) {
        this.errorMessage = 'Some war reports could not be fetched, but the process continued.';
      }
    },

    // New method to toggle the body class based on visibility of light/dark mode
    updateHtmlClass() {
      const lightMode = document.querySelector('.light-mode').parentElement.parentElement;
      const darkMode = document.querySelector('.dark-mode').parentElement.parentElement;

      if (lightMode.hidden) {
        document.documentElement.classList.add('visual-mode-dark'); // Change from body to html
        document.documentElement.classList.remove('visual-mode-light');
      } else if (darkMode.hidden) {
        document.documentElement.classList.add('visual-mode-light'); // Change from body to html
        document.documentElement.classList.remove('visual-mode-dark');
      }
    },

    // Set up the click listeners for the toggle elements
    setupToggleListener() {
      document.querySelectorAll('.toggle-light-dark-mode').forEach(item => {
        item.addEventListener('click', () => {
          document.querySelectorAll('.toggle-light-dark-mode').forEach(el => el.hidden = !el.hidden);
          this.updateHtmlClass(); // Updated to use the new method
        });
      });
    }
  }
};
</script>

<style scoped>
.light-mode, .dark-mode {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: 110% 110%;
}

.light-mode {
  background-image: url(@/assets/light-mode.svg);
}

.dark-mode {
  background-image: url(@/assets/dark-mode.svg);
}

.twisted-logo {
  height: 40px;
  width: 180px;
  background-repeat: no-repeat;
  background-image: url(@/assets/twisted-b.svg);
}

.visual-mode-dark .twisted-logo {
  background-image: url(@/assets/twisted-w.svg);
}
</style>
