import { createApp } from 'vue'; // Correct import for Vue 3
import App from './App.vue';
import router from './router'; // Import the router
import 'uikit/dist/css/uikit.min.css';
import './css/main.css'
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

const app = createApp(App);
app.use(router); // Add the router here
app.mount('#app');
