<template>
  <div v-if="isDataReady" class="uk-card uk-card-default uk-width-1-1">
    <div class="uk-card-header">
      <div class="uk-grid-small uk-flex-middle" uk-grid>
        <div class="uk-width-auto">
          <div class="uk-form-controls">
            <div class="uk-inline uk-inline-select-overlayed">
              <label for="participantSelector">Member:</label>
              <select
                id="participantSelector"
                class="uk-select uk-form-width-custom-280 uk-form-small uk-text-right"
                v-model="selectedMemberId"
                @change="updateChartData"
              >
                <option
                  v-for="member in filteredMembers"
                  :key="member.member_id"
                  :value="member.member_id"
                >
                  {{ member.member_name }} ({{ member.member_id }})
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card-header">
      <h3 class="uk-card-title uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-default" style="    line-height: 1.3;
    margin: 5px 0px;padding-top:0;">War Results - Minimal Participation</h3>
      <p class="uk-text-meta uk-margin-remove-top">Showing wars were each member had at least 1 hit compared to wars with none.</p>
    </div>

    <div class="uk-card-body half-gauge-chart">
      <Doughnut :data="chartData" :options="chartOptions" />
      <div class="center-text">{{ percentage }}<span class="percent">%</span></div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

export default {
  components: {
    Doughnut,
  },
  props: {
    warParticipationData: Object,
    membershipData: Array,
    isDataReady: Boolean,
  },
  data() {
    return {
      selectedMemberId: '',
      percentage: 0, // Display percentage in the center
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 0,
          bottom: 0,
        },
      },
      chartData: {
        labels: ['Participated', 'Not Participated'],
        datasets: [
          {
            data: [0, 100], // Default values
            backgroundColor: ['rgba(62, 101, 175, 1.0)', 'rgba(244, 244, 245, 1.0)'],
            borderColor: ['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'], // Default white border
            borderWidth: 3,
            cutout: '80%', // Makes the doughnut thinner
          },
        ],
      },
      chartOptions: {
        responsive: true,
        rotation: -90, // Start the chart from the top
        circumference: 180, // Only show the upper half (half-doughnut)
        animations: {
          borderColor: {
            duration: 0, // Disable border color animation
          },
        },
        plugins: {
          legend: {
            display: false, // Hides the legend
          },
          tooltip: {
            enabled: false, // Disable the tooltip
          },
        },
      },
    };
  },
  computed: {
    filteredMembers() {
      if (!this.warParticipationData || !this.warParticipationData.members) {
        console.warn('No members data available');
        return [];
      }

      return this.warParticipationData.members.filter(member => {
        const hasParticipatedFlags = member.member_wars_participated > 0;
        return hasParticipatedFlags; // Only include members with flags > 0
      });
    },
  },
  watch: {
    warParticipationData: {
      immediate: true,
      handler(newData) {
        if (newData && newData.members && newData.members.length > 0) {
          this.selectedMemberId = newData.members[0].member_id;
          this.updateChartData();
        }
      },
    },
  },
  mounted() {
    // Watch for changes in the "visual-mode-dark" class using MutationObserver
    const observer = new MutationObserver(() => {
      this.updateChartData(); // Recalculate border color when dark mode toggles
    });

    // Start observing the documentElement's class changes
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'], // Only watch class changes
    });
  },
  methods: {
    updateChartData() {
      const selectedMember = this.warParticipationData.members.find(
        (member) => member.member_id === this.selectedMemberId
      );

      if (selectedMember) {
        const memberWarsPresent = selectedMember.member_wars_present || 0;
        const memberWarsParticipated = selectedMember.member_wars_participated || 0;

        // Calculate percentages
        const total = memberWarsPresent;
        const participatedPercentage = total > 0 ? (memberWarsParticipated / total) * 100 : 0;
        const notParticipatedPercentage = 100 - participatedPercentage;

        // Check for dark mode
        const isDarkMode = document.documentElement.classList.contains('visual-mode-dark');
        const borderColor = isDarkMode ? '#242526' : 'rgba(255, 255, 255, 1)';

        // Update the chart data and dynamically set the border color
        this.chartData = {
          labels: ['Participated', 'Not Participated'],
          datasets: [
            {
              data: [participatedPercentage, notParticipatedPercentage],
              backgroundColor: ['rgba(62, 101, 175, 1.0)', 'rgba(244, 244, 245, 1.0)'],
              borderColor: [borderColor, borderColor], // Dynamically change the border color
              borderWidth: 3,
              cutout: '80%', // Maintain doughnut style
            },
          ],
        };

        this.percentage = Math.round(participatedPercentage); // Update the center percentage
      } else {
        console.warn('No selected member found');
      }
    },
  },
};
</script>

<style scoped>
.uk-card {
  margin-bottom: 20px;
}

.uk-form-width-custom-280 {
  width: 300px;
}

.uk-inline-select-overlayed label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #71717a;
  font-size: 14px;
}

.uk-inline-select-overlayed .uk-select {
  padding-left: 60px !important;
  padding-right: 40px !important;
  text-align: right;
  border-radius: 5px;
}

.uk-card-default {
  border: 1px solid #e4e4e7 !important;
  box-shadow: none !important;
  border-radius: 5px !important;
}

.half-gauge-chart {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.half-gauge-chart canvas {
  height: 300px !important;
  width: 300px !important;
}

.half-gauge-chart .center-text {
  font-size: 65px;
  font-weight: bold;
  position: absolute;
  position: absolute;
  bottom: 44px;
  padding-left: 8px;
}

.half-gauge-chart .percent {
  color: #cccccc;
  font-size: 30px;
  vertical-align: super;
  padding-left: 2px;
}
</style>
